import React from "react"
import PropTypes from "prop-types"

import BreadCrumb from "../components/breadcrumb"

const InnerLayout = ({ location, children }) => (
  <>
    <BreadCrumb location={location} />
    <section className="inner-page">{children}</section>
  </>
)

InnerLayout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired,
}

export default InnerLayout
