import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

const BreadCrumb = ({ location }) => {
  const { pathname } = location

  /** convert string to title case */
  const toTitleCase = (text) => {
    if (!text.length) return
    let title = text[0].toUpperCase()
    title += text.slice(1)
    return title
  }

  const nodes = pathname
    .split("/")
    .map((n) => toTitleCase(n))
    .filter((n) => !!n)

  return (
    <section className="breadcrumbs">
      <div className="container">
        <div className="d-flex justify-content-between align-items-center">
          <h2>{nodes[nodes.length - 1]}</h2>
          <ol>
            <li>
              <Link to="/">Accueil</Link>
            </li>
            {nodes.map((n, i) => (
              <li key={i}>{n}</li>
            ))}
          </ol>
        </div>
      </div>
    </section>
  )
}

BreadCrumb.propTypes = {
  location: PropTypes.object.isRequired,
}

export default BreadCrumb
