import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import InnerLayout from "../components/innerLayout"

const PortFolio = ({ location }) => (
  <Layout>
    <SEO />
    <InnerLayout location={location}>
      <div className="container">
        <h1>Hello</h1>
      </div>
    </InnerLayout>
  </Layout>
)

export default PortFolio
